.required:after {
  content: " *";
  color: red;
}

tr:nth-child(even) {
  @apply bg-gray-50
}

tr:nth-child(odd) {
  @apply bg-white
}

input:disabled,
select:disabled{
  @apply bg-gray-100
}

