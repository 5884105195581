@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700;800;900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


.loading-indicator:before {
    content: '';
    background: #1d8efa54;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .loading-indicator:after {
    content: url('./assets/spinner.png');
    position: fixed;
    width: 100%;
    top: 50%;
    left: 0;
    z-index: 1001;
    color:white;
    text-align:center;
    font-weight:bold;
    font-size:1.5rem; 
    @apply animate-spin       
  }
  
  .react-toast-notifications__toast__content{
    font-family: 'Poppins' !important;
  }

  /* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #e6b041 #2b3646;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 4px;
}

*::-webkit-scrollbar-track {
  background: #fff;
}

*::-webkit-scrollbar-thumb {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid #fff;
}

.no-scrollbar {
  -ms-overflow-style: none !important; /* IE and Edge */
  scrollbar-width: none !important; /* Firefox */
}

.css-1s2u09g-control{
  border-color:#fff !important;
  outline: 0 !important;
}

.css-1hb7zxy-IndicatorsContainer{
  display: none !important;
}

.react-datepicker-wrapper:focus{
  outline: none !important;
}

.css-1hb7zxy-IndicatorsContainer{
  display: none !important;
}