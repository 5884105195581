/* @media print {
  body {
    font-size: "16px";
    color: "lightgrey";
  }

  .no-break-inside {
    break-inside: "avoid";
  }

  .break-before {
    break-before: "always";
  }  
} */

@page {
  size: A4;
  size: landscape;
}

@media all {
  .pagebreak {
    display: none;
  }
}

@media print {
  .pagebreak {
    page-break-before: always;
  }
}