.b2b_exchange {
  width: 50px;
  transition: width 0.4s;
}

.b2b_exchange:hover {
  width: 370px;
}

.b2b_exchange:hover .b2b_ex_container {
  display: block;
}
.b2b_ex_container {
  display: none;
}

.b2b_exchange:hover .ex_img{
    display: none;
}
